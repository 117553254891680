<template>
    <div>
        <b-tabs>
            <b-tab-item label="Datos Generales" icon="file-alt">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <b-field label="Nombre" label-position="on-border">
                            <b-input v-model="data.name" required></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Correo" label-position="on-border">
                            <b-input v-model="data.email"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Teléfono" label-position="on-border">
                            <b-input v-model="data.phone"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Fecha de Nacimiento" label-position="on-border">
                            <b-datepicker :max-date="new Date()" :value="date" @input="onSetDate"></b-datepicker>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <select-speciality v-model="data.speciality"></select-speciality>
                    </div>
                    <div class="column is-6">
                        <b-field label="Cedula" label-position="on-border">
                            <b-input v-model="data.professional_licence"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="RFC" label-position="on-border">
                            <b-input v-model="data.rfc" maxlength="14"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Contraseña" label-position="on-border">
                            <b-input v-model="data.password" type="password"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Representante" label-position="on-border">
                            <b-input v-model="data.forwarder"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field label="Direccion" label-position="on-border">
                            <b-input v-model="data.address"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-12">
                        <b-field label="Observaciones" label-position="on-border">
                            <b-input type="textarea" v-model="data.comment"></b-input>
                        </b-field>
                    </div>
                </div>
            </b-tab-item>
            <b-tab-item label="Empresas" icon="building" v-if="edit">
                <form-medical-companies
                    :medical="data"
                ></form-medical-companies>
            </b-tab-item>
        </b-tabs>

    </div>
</template>

<script>
    import SelectSpeciality from "./SelectSpeciality";
    import FormMedicalCompanies from "./FormMedicalCompanies";
    export default {
        name: "FormMedical",
        components: {FormMedicalCompanies, SelectSpeciality},
        props: {
            data: Object,
            edit: Boolean
        },
        computed: {
            date() {
                return this.data.birthday ? new Date(this.data.birthday) : null;
            }
        },
        methods: {
            onSetDate(date) {
                this.data.birthday = this.$moment(date).format();
            }
        }
    }
</script>

<style scoped>

</style>
