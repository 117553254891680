<template>
  <div>
    <div class="columns">
      <div class="column is-4">
        <b-field label="Empresa">
          <b-autocomplete
              :data="data"
              field="name"
              placeholder="Empresa"
              :loading="isFetching"
              @typing="getAsyncData"
              @input="onInput"
              @blur="onBlur"
              ref="select_company"
              v-model="localValue"
              @select="onSelect"
              autofocus>

            <template slot-scope="props">
              <div class="media">
                <div class="media-left">
                  <img width="32" :src="props.option.logo" alt="Logo">
                </div>
                <div class="media-content">
                  {{ props.option.name }}
                  <br>
                  <small>
                    {{ $t('company.attributes.company') }} {{ props.option.company }}<br>
                    {{ $t('common.attributes.email') }} <b>{{ props.option.email }}</b>
                  </small>
                </div>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field label="Clave con la empresa">
          <b-input v-model="companyCode"></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-button @click="onAdd">Agregar</b-button>
      </div>
    </div>

    <b-table
        :data="medical.medical_companies"
        :columns="columns"
        striped
        bordered
    >

    </b-table>
  </div>

</template>

<script>
    import debounce from "lodash/debounce";

    export default {
      name: "FormMedicalCompanies",
      data() {
        return {
          companyCode: '',
          data: [],
          selected: null,
          isFetching: false,
          apiUrl: 'companies',
          params: {
            page: 1,
            perPage: 20,
            q: ''
          },
          localValue: '',
          columns: [
            {
              field: 'company.code',
              label: 'Clave de la empresa',
            },
            {
              field: 'company.name',
              label: 'Empresa',
            },
            {
              field: 'code',
              label: 'Clave con las empresas',
            },
          ]
        }
      },
      props: {
        medical: Object
      },
      methods:{
        // You have to install and import debounce to use it,
        // it's not mandatory though.
        getAsyncData: debounce(function (name) {
          this.data = [];
          if (!name.length) {
            this.data = []
            return
          }
          this.isFetching = true
          this.params.q = name

          this.getAll(this.params)
              .then(({ data = [] }) => {
                this.data = []
                data.forEach((item) => this.data.push(item))
              })
              .catch((error) => {
                this.data = []
                throw error
              })
              .finally(() => {
                this.isFetching = false
              })
        }, 500),
        onSelect(data = null) {
          if (data === null) {
            return
          }
          this.selected = data;
        },
        onInput(val) {
          if (val === '') {
            this.onSelect(val);
          }
        },
        onBlur() {
          setTimeout(() => {
            if (!this.selected) {
              this.localValue = '';
            }
          }, 600);
        },
        onAdd(){
          this.$loader.show();
          this.$http.post(`medical/${this.medical.id}/company`, {
            company: this.selected.company,
            code: this.companyCode
          }).then(() => {
            this.medical.medical_companies.push({
              code: this.companyCode,
              company: {
                code: this.selected.company,
                name: this.selected.name
              }
            })
            this.localValue = '';
            this.companyCode = '';
          }).finally(() => {
            this.$loader.hide();
          })
        }
      }
    }
</script>

<style scoped>

</style>
