<template>
    <b-field label="Especialidad" label-position="on-border">
        <b-select placeholder="Selecciona una especialidad"
                  expanded
                  @input="val => $emit('input', val)"
                  :value="value">
            <option
                    v-for="option in data"
                    :value="option.code"
                    :key="option.code">
                {{ option.name }}
            </option>
        </b-select>
    </b-field>
</template>

<script>
    export default {
        name: "SelectSpeciality",
        props: {
            value: String,
        },
        computed: {
            data() {
                return this.$store.state.medical.specialities
            }
        }
    }
</script>

<style scoped>

</style>